#election-results-without-google .pure-election-table{
  padding-left: 2em;
}

#election-results-without-google .section-small-header {
  padding-left: 1.5em;
}

#actual-election-results .pure-election-table {
  padding-right: 2em;
}

#pure-elections-flipped .pure-row-compressed {
  height: 54px;
  align-items: center;
}

#pure-elections-flipped img {
  margin-right: 1em;
}

#pure-elections-flipped .see-results {
  text-align: right;
}

.pure-election-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.pure-election-table thead {
  width: fit-content;
  margin-top: -2.5px;
  margin-bottom: -0.5px;
  color: #585757;
  font-size: 24px;
  letter-spacing: 0px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
}

.pure-election-table tbody {
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #1c1c1c;
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: normal;
}

.pure-election-table table, th, td {
  border: 1px solid #ccc;
}

.pure-election-table th, td {
  padding: 8px;
  text-align: left;
  vertical-align: middle;
}

.pure-election-table td {
  min-height: 60px;
  height: 60px;
  max-height: 60px;
}

.pure-election-table tbody td:nth-child(2) {
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--light-theme-background);
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: normal;
}

.pure-election-table .pure-election-winner {
  display:  inline-flex;
}

.pure-election-table thead th:nth-child(2),
.pure-election-table thead th:nth-child(3),
.pure-election-table tbody td:nth-child(2),
.pure-election-table tbody td:nth-child(3) {
  text-align: center;
  vertical-align: middle;
}

.pure-election-table tbody tr.pure-democrat-row td:nth-child(2) {
  background-color: #3230cc;
}

.pure-election-table tbody tr.pure-democrat-row td:nth-child(3) {
  color: #3230cc;
}

.pure-election-table tbody tr.pure-republican-row td:nth-child(2) {
  background-color: #c1250f;
}

.pure-election-table tbody tr.pure-republican-row td:nth-child(3) {
  color: #c1250f;
}

.pure-icon-no-google {
  height: 40px;
  position: relative;
  width: 40px;
  margin-right: 0.3em;
  top: 4px;
}

.pure-icon-no-google-container {
  position: relative;
  border: 4px solid;
  border-color: #969696;
  border-radius: 20px;
  height: 32px;
  left: 0;
  top: 0;
  width: 32px;
}  

.pure-icon-no-google .svg-vector {
  height: 22px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 22px;
}

.pure-icon-no-google .svg-line {
  height: 32px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 32px;
}