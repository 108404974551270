@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
/* TODO: Inline this font as part of our assets */

:root {
  /* Adjust this value once we hit 100M or 1B ephemeral experiences captured. */
  --odometer-reference-pixels: 1100;
  --odometer-current-pixels: 1100; /* Ovewritten by JS */
  --odometer-scale: calc(var(--odometer-current-pixels) / var(--odometer-reference-pixels));
}

.section-large-header {
  font-size: 3.5em !important;
  font-weight: 600;
  color: #585757;
}


#total_experiences_captures button {
  font-size: 1em !important;
}

.full-opacity {
  opacity: 1.0 !important;
}

.odometer {
  font-family: "Roboto" !important;
  font-weight: 900 !important;
  font-size: calc(var(--odometer-scale) * 78px) !important;
  line-height: calc(var(--odometer-scale) * 122px) !important;
  height: 1em !important;
  margin-bottom: 1em;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: 0.0;
}

.odometer-formatting-mark {
  color: #585757;
  font-weight: 900 !important;  
  padding: calc(var(--odometer-scale) * 20px);
}

.odometer-digit {
  font-weight: 900 !important;
  font-family: "Roboto", Helvetica, sans-serif !important;
  padding-left: calc(var(--odometer-scale) * 20px) !important;
  padding-right: calc(var(--odometer-scale) * 20px) !important;
  text-align: center !important;
}

.odometer-digit + .odometer-digit {
  margin-left: calc(var(--odometer-scale) * 8px) !important;
}

.odometer-digit-spacer {
  width: calc(var(--odometer-scale) * 60px);
}

.odometer-digit-inner {
  left: calc(var(--odometer-scale) * 18px) !important;
}

.odometer-value {
  padding-left: calc(var(--odometer-scale) * 10px);
  padding-right: calc(var(--odometer-scale) * 10px);
}

#eph-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: larger;
  padding: 0;
  text-decoration: underline;
}
.larger-text {
  font-size: 1.6em;
}

.larger-text-big-tech {
  font-size: 1.8em;
}
