.footer-container {
  margin-left: 275px;
}

footer {
  padding-top: 4em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #c1250f;
  color: #f6f6f6;
  position: relative;
  transition: margin-left 0.3s ease-in-out;
  top: 4px;
}

@media screen and (max-width: 1024px) {
  .footer-container {
    margin-left: 0em;
  }
}

footer a,
footer a:hover,
footer a:visited,
footer a:active {
  color: #f6f6f6;
  text-decoration: none;
  font-family: "Inter-Medium",Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.25px;
}

footer p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .1px;
}

footer .pure-logo {
  margin-left: 1em;
  margin-top: 0;
  height: 39px;
}

footer .pure-logo-shield {
  height: 30px;
  width: 24px;
  top: -6px;
  margin-right: 6px;
  position: relative;
}

footer .pure-logo-star {
  height: 9px;
  left: 53px;
  position: relative;
  top: -4px;
  width: 10px;
}

footer .pure-logo-text {
  height: 34px;
  width: 105px;
  margin-left: -13px;
}

#footer-links {
  gap: 1em;
  text-align: center;
  align-items: center;
  justify-content: center;
}
