#navigation {
  position: fixed;
  top: 0;
  left: 0;
  /*
  left: -100%;
  */
  transform: translateX(-100%);
  width: 275px;
  height: 100%;
  background-color: #fdfdfd;
  padding: 20px;
  box-shadow: var(--shadow);
  transition: all 0.3s ease-in-out;
  z-index: 30;
  border-right-style: solid;
  border-right-width: 3px;
  border-color: #e8e8e8;
  box-sizing: border-box;
  opacity: 0.0;
  zoom: 70%;
}

@media screen and (min-width: 1300px) {
  #navigation {
    zoom: 100%;
  }
}

#navigation.active {
  left: 0px;
  transform: translateX(0%);
  padding: 20px;
  opacity: 1.0;
}


#hamburger-icon {
  position: fixed;
  top: 0;
  left: 0;
  width: 7em;
  height: 7em;
  margin-left: 2em;
  margin-top: 2em;
  background-color: #fdfdfd;
  border-right: 2px #a8a8a8 solid;
  border-bottom: 2px #a8a8a8 solid;
  border-left: 2px #a8a8a8 solid;
  border-top: 0;
  border-radius: 10px; /* Rounded bottom right corner */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* TODO: This only works with active, when I change the name it fails???*/
#hamburger-icon.active {
  opacity: 1.0 !important;
}

#navigation .pure-logo {
  margin-left: 1em;
  margin-top: 1em;
  height: 51px;
}

#navigation .pure-logo-shield {
  height: 38px;
  width: 31px;
  top: -6px;
  position: relative;
}

#navigation .pure-logo-star {
  height: 12px;
  left: 74px;
  position: relative;
  top: -7px;
  width: 13px;
}

#navigation .pure-logo-text {
  height: 41px;
  width: 139px;
  margin-left: -13px;
}

.navigation-options {
  padding-top: 0.5em;
}

.navigation-option {
  width: 100%;
  margin-bottom: 0.25em;
  border-bottom: 2px #e8e8e8 solid;
  align-items: center;
}

.navigation-option-icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.navigation-option-active {
  background-color: #e8e8e8;
  border-radius: 4px;
  border-bottom: 0;
}

.navigation-option-active p {
  color: #1c1c1c;
}

.navigation-option a {
  color: #585757;
  text-decoration: none;
}

.button-gold {
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(180deg,#f7cb4e,#e4a710);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 16px;
  width: 220px;
  border: 0;
  letter-spacing: 1.25px;
}

.button-gold a {
  color: #1c1c1c;
  text-decoration: none;
}

.timer {
  margin-top: 1em;
  margin-bottom: 1em;
  color: #969696;
}

.timer-value {
  font-family: Inter,Helvetica;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: 0;
  line-height: normal;
}

.timer-label {
  color: #585757;
  font-family: Inter,Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.77px;
  width: fit-content;
}

#navigation p {
  font-size: 24px;
}