@import url("https://fonts.googleapis.com/css?family=Inter:600,500,400,700");

:root {
  --bg-color: rgba(246, 246, 246, 1);
  --cover-info: rgba(193, 199, 205, .65);
  --light-theme-card: rgba(253, 253, 253, 1);
  --shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
  --light-theme-darkest-text: rgba(28, 28, 28, 1);
  --light-theme-background: rgba(246, 246, 246, 1);
  --light-theme-medium-grey: rgba(150, 150, 150, 1);
  --democrat-blue-main-color: rgba(26, 106, 255, 1);
  --democrta-blue-darkest: rgba(50, 48, 204, 1);
  --democrat-blue-lightest: rgba(77, 177, 255, 1);
  --democrat-blue-lighter: rgba(0, 141, 255, 1);
  --republican-red-main-color: rgba(255, 72, 38, 1);
  --republican-red-darkest: rgba(193, 37, 15, 1);
  --republican-red-darker: rgba(232, 59, 29, 1);
  --republican-red-light: rgba(255, 131, 102, 1);
  --purple: rgba(122, 54, 195, 1);
}

* {
  -webkit-font-smoothing: antialiased;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f6f6f6;
}

#banner {
  position: static;
  margin-bottom: -30em;
  background: linear-gradient(0deg,#f6f6f6,hsla(0,0%,96%,.74) 28.12%,hsla(0,0%,96%,0));

}

#banner div {
  z-index: -1;
  background-image: url(../assets/header.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 583px;
  position: relative;
  background-position-x: -8px;
  background-repeat: no-repeat;
}

.section-container {
  position: relative;
}

#dashboards {
  margin-left: 275px;
  /* transition: margin-left 0.3s ease-in-out;*/
}

.sections {
  margin-right: 1em;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.section {
  padding: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 2em;
  background-color: var(--light-theme-card);
  border-radius: 8px;
  box-shadow: var(--shadow);
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-large-header {
  color: var(--light-theme-darkest-text);
  font-size: 47px;
  letter-spacing: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  line-height: normal;
}

.hero-details,
.section-large-details {
  font-family: "Inter", Helvetica;
  font-weight: 500;
  font-size: 34px;
  letter-spacing: 0;
  line-height: 44.2px;
  color: #585757;
}

.hero-details {
  fonti-size: 33px;
  font-weight: 400;
}

.text-red {
  color: #c1250f;
}

.new-line {
  display: block;
  content: "";
  margin: 0;
  padding: 0;
  height: 1.5em;
}

.text {
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #1c1c1c;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 28.799999237060547px;
  font-style: normal;
}

.section-small-header {
  font-size: 1.4em !important;
}

.section-small-header a {
  color: #1c1c1c;
  text-decoration: underline;
}

.small-header {
  font-size: 24px;
}

.hero-header {
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: #585757;
  font-size: 60px;
  letter-spacing: -0.5px;
  line-height: 50px;
}

.pure-row {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.pure-row-centered {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.pure-row-compressed {
  display: inline-flex;
}

.pure-row-spread {
  display: inline-flex;
  width: 100%;
  flex-direction: initial;
  align-items: flex-start;
}

.pure-row-staggered {
  display: inline-flex;
  flex-direction: initial;
  align-items: flex-start;
}

.pure-info-icon {
  width: 24px;
  height: 24px;
  top: 16px;
  left: 1072px;
  background-color: var(--light-theme-medium-grey);
  border-radius: 12px;
  cursor: pointer;
  z-index: 999;
}

.pure-info-icon-container {
  position: relative;
  width: 4px;
  height: 16px;
  top: 4px;
  left: 10px;
}

.pure-info-icon-dot {
  position: absolute;
  width: 4px;
  height: 11px;
  top: 5px;
  left: 0;
  background-color: var(--light-theme-background);
  border-radius: 6.15px;
}

.pure-info-icon-line {
  position: absolute;
  width: 4px;
  height: 4px;
  top: 0;
  left: 0;
  background-color: var(--light-theme-background);
  border-radius: 6.15px;
}

.pure-info-icon-corner-adjustment {
  margin-top: -0.5em;
}

.text {
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #1c1c1c;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 28.799999237060547px;
  font-style: normal;
}

.text-gray {
  color: rgba(150, 150, 150, 1);;
}

.text-small {
  font-size: 16px;
  line-height: 26px;
}

.text-black {
  color: #000000;
}

.text-blue {
  color: #3230cc;
}

.text-red {
  color: #c1250f;
}

.text-purple {
  color: #7a36c3;
}

.text-yellow {
  color: #e4a710;
}
