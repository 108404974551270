.msg-box-text p {
  font-size: 1.2rem;
  /* extra line for the text */
  margin-top: 1rem;
}

.popup-info-box-style{
  width: calc(min(70vw, 800px));
}

.swal2-actions button {
  background-color: var(--democrta-blue-darkest) !important;
}