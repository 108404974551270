#pure-balanced-data-collection {
  margin-right: 0.5em;
  text-align: -webkit-center;
}

#pure-balanced-data-collection .pure-row {
  text-align:  left;
}

#methodology {
  margin-left: 0.5em;
}

#methodology .text {
  text-align: left;
}

#methodology .text-gray {
  margin-top: 0.25em;
}

#methodology p {
  font-size: 17px;
}

#data-collection-chart-container {
  padding-top: 3em;
  padding-bottom: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#data-collection-chart {
  text-align: center;
  justify-content: center;
}
