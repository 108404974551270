/* General responsive layout */

.pure-g {
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-left: 0 !important;
    margin-bottom: 3em;
  }

  footer > div > div:nth-child(1){
    text-align: center;
  }

  #election-results-without-google .section-small-header {
    margin-top:  1em;
    padding-left: 0 !important;
  }

  #election-results-without-google .election-table {
    padding-left: 0 !important;
  }

  .bias-graph-large canvas,
  .bias-graph-small canvas,
  .vote-graph-large canvas,
  .vote-graph-small canvas {
    width: 100%;
    height: 100%;
  }

  #actual-election-results {
    margin-left: 2em;
  }

  #election-results-without-google {
    margin-left: 2em;
  }

  #actual-election-results .pure-election-table {
    padding-right: 0;    
  } 

  #election-results-without-google .pure-election-table {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 2em;
  }

  #banner div {
    z-index: -1;
    background-position: 50% 50%;
    background-size: cover;
    height: 586px;
    width: 100%;
  }
}

/* Navigation */

/* reduced screen */
@media screen and (max-width: 1024px) {
  #dashboards {
    margin-left: 0em;
  }
}

/* Bias Graphs */

@media screen and (min-width: 760px) and (max-width: 1300px) {
  #our-mission .small-header {
    font-size: 2.5em !important;
    line-height: 1em;
  }

  #our-mission .text-small {
    font-size: 16px !important;
    line-height: 1.2em;
  }
}
  

@media screen and (min-width: 100px) and (max-width: 1278px) {

  #our-mission .text-small {
    font-size: 24px !important;
    line-height: 1.2em;
  }
}

/* Balanced Data */

@media screen and (min-width: 100px) and (max-width: 1300px) {
  #methodology .section-small-header {
    font-size: 2.5em !important;
    line-height: 1em;
  }

  #methodology .text {
    font-size: 26px;
    line-height: 1.2em;
  }
}

/* Odometer (will need to be redone once we get to 100M then again at 1B) */
/*
@media screen and (max-width: 750px) {
  .odometer {
    font-size: 50px !important;
    line-height: 70px !important;
    height: 2em !important;
  }

  .odometer-formatting-mark {
    padding: 10px !important;
    font-size: 40px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .odometer {
    font-size: 20px !important;
    line-height: 50px !important;
    height: 3.25em !important;
  }
  .odometer-formatting-mark {
    padding: 5px !important;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .odometer {
    font-size: 30px !important;
    line-height: 60px !important;
    height: 3.25em !important;
  }
  .odometer-formatting-mark {
    padding: 5px !important;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .odometer {
    font-size: 50px !important;
    line-height: 90px !important;
    height: 2.5em !important;
  }

  .odometer-formatting-mark {
    padding: 5px !important;
    font-size: 30px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .odometer {
    font-size: 40px !important;
    line-height: 80px !important;
    height: 2.75em !important;
  }

  .odometer-formatting-mark {
    padding: 10px !important;
    font-size: 40px !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .odometer {
    font-size: 70px !important;
    line-height: 110px !important;
  }

  .odometer-formatting-mark {
    font-size: 70px !important;
  }
}

*/