#usa-political-landscape {
  margin-right: 0.5em;
}

#content-bias-map {
  margin-left: 0.5em;
}

#content-bias-map .hero-details {
  font-size: 32px;
}

#content-bias-map-container {
  height: 23em;
  aspect-ratio: 1.9;
}


#usa-political-landscape img {
  height: 23em;
}

#content-bias-map-canvas {
  transform: translateX(-2em);
}

/*

<canvas id="content-bias-map-canvas" style="display: block; box-sizing: border-box; height: 213px; width: 426px;" width="532" height="266"></canvas>

*/



@media screen and (min-width: 1600px) {
  #usa-political-landscape img {
    height: 20em;
  }
  #content-bias-map-container {
    height: 20em;
  }
}

@media screen and (max-width: 1600px) {
  #usa-political-landscape img {
    height: 16em;
  }
  #content-bias-map-container {
    height: 16em;
  }
}


