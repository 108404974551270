#info-video {
  position: fixed;
  top: 10vh;
  right: 0vw;
  width: max(20%, 300px);
  /*background-color: rgba(0, 0, 0, 0);*/
  border: 0;
  z-index: 9999;
  opacity: 0;
  transition: opacity 1s;
}


#info-video video {
  width: 100%;
}

#info-video.active {
  opacity: 1;
}

