.slide-tray {
  display: flex;
  position: relative;
  width: 175px;
  height: 200px;
  z-index: 10;
}

.slide-tray u {
  text-decoration: underline;
  text-underline-offset: 2px;
}

@media (max-width: 800px) {
  .slide-tray {
    width: 125px !important;
  }
}

.slide-tray-small {
  width: 125px !important;
}

.left-div, .right-div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 2.5s ease, opacity 0.75s cubic-bezier(0.5, 0, 1, 1);
}

.left-div {
  transform: translate(-25%);
  opacity: 0;
  left: -10%;
}

.right-div {
  opacity: 1;
  left: 2.5%;
}

#google-bias {
  margin-right: 0.5em;
}

#google_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #google-bias {
    padding-top: 0.5em;
  }

  #google-bias .bias-header-left {
    margin-right: 1em;
  }

  #google-bias .pure-row {
    margin-top: 1em;
  }

  #google_weekly_bias_box,
  #google_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #google_weekly_bias_box .bias-value,
  #google_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #google_weekly_bias_box .bias-type,
  #google_bias_box .bias-type {
    margin-top: -15px;
  }
}

#bing-bias {
  margin-left: 0.5em;
}

#bing_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #bing-bias {
    padding-top: 0.5em;
  }

  #bing-bias .bias-header-left {
    margin-right: 1em;
  }

  #bing-bias .pure-row {
    margin-top: 1em;
  }

  #bing_weekly_bias_box,
  #bing_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #bing_weekly_bias_box .bias-value,
  #bing_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #bing_weekly_bias_box .bias-type,
  #bing_bias_box .bias-type {
    margin-top: -15px;
  }
}

#youtube-bias {
  margin-right: 0.5em;
}

#youtube_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #youtube-bias {
    padding-top: 0.5em;
  }

  #youtube-bias .bias-header-left {
    margin-right: 1em;
  }

  #youtube-bias .pure-row {
    margin-top: 1em;
  }

  #youtube_weekly_bias_box,
  #youtube_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #youtube_weekly_bias_box .bias-value,
  #youtube_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #youtube_weekly_bias_box .bias-type,
  #youtube_bias_box .bias-type {
    margin-top: -15px;
  }
}

#yahoo-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#yahoo_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #yahoo-bias {
    padding-top: 0.5em;
  }

  #yahoo-bias .bias-header-left {
    margin-right: 1em;
  }

  #yahoo-bias .pure-row {
    margin-top: 1em;
  }

  #yahoo_weekly_bias_box,
  #yahoo_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #yahoo_weekly_bias_box .bias-value,
  #yahoo_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #yahoo_weekly_bias_box .bias-type,
  #yahoo_bias_box .bias-type {
    margin-top: -15px;
  }
}

#our-mission {
  margin-left: 0.5em;
}

#facebook-bias {
  margin-right: 0.5em;
}

#facebook_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #facebook-bias {
    padding-top: 0.5em;
  }

  #facebook-bias .bias-header-left {
    margin-right: 1em;
  }

  #facebook-bias .pure-row {
    margin-top: 1em;
  }

  #facebook_weekly_bias_box,
  #facebook_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #facebook_weekly_bias_box .bias-value,
  #facebook_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #facebook_weekly_bias_box .bias-type,
  #facebook_bias_box .bias-type {
    margin-top: -15px;
  }
}

#reddit-bias {
  margin-right: 0.5em;
}

#reddit_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #reddit-bias {
    padding-top: 0.5em;
  }

  #reddit-bias .bias-header-left {
    margin-right: 1em;
  }

  #reddit-bias .pure-row {
    margin-top: 1em;
  }

  #reddit_weekly_bias_box,
  #reddit_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #reddit_weekly_bias_box .bias-value,
  #reddit_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #reddit_weekly_bias_box .bias-type,
  #reddit_bias_box .bias-type {
    margin-top: -15px;
  }
}

#tiktok-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#tiktok_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #tiktok-bias {
    padding-top: 0.5em;
  }

  #tiktok-bias .bias-header-left {
    margin-right: 1em;
  }

  #tiktok-bias .pure-row {
    margin-top: 1em;
  }

  #tiktok_weekly_bias_box,
  #tiktok_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #tiktok_weekly_bias_box .bias-value,
  #tiktok_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #tiktok_weekly_bias_box .bias-type,
  #tiktok_bias_box .bias-type {
    margin-top: -15px;
  }
}

#x-bias {
  margin-left: 0.5em;
}

#x_weekly_bias_box {
  margin-right: 1em;
}

@media (max-width: 800px) {
  #x-bias {
    padding-top: 0.5em;
  }

  #x-bias .bias-header-left {
    margin-right: 1em;
  }

  #x-bias .pure-row {
    margin-top: 1em;
  }

  #x_weekly_bias_box,
  #x_bias_box {
    max-width: 400px;
    padding: 10px;
    padding-top: 25px;
  }
  
  #x_weekly_bias_box .bias-value,
  #x_bias_box .bias-value {
    padding: 5px;
    font-size: 38px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  #x_weekly_bias_box .bias-type,
  #x_bias_box .bias-type {
    margin-top: -15px;
  }
}

.bias-number {
  background-color: blue;
  top: -1.5em;
  left: 0.25em;
  right: 0;
  box-shadow: var(--shadow);
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  z-index: 1;
  zoom: 85%
}

.bias-number-large {
  padding: 20px 15px 16px;
}

.bias-header {
  align-items: center;
  display: inline-flex;
  height: 3em;
  margin-bottom: 2em;
}

.bias-header .section-large-header {
  font-size: 40px;
}

.bias-value {
  color: #fff;
  font-family: Inter,Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.bias-type {
  color: #fff;
  font-family: Inter,Helvetica;
  font-weight: 400;
  position: relative;
  width: fit-content;
  text-align: center;
}

.bias-number-large .bias-value {
  font-size: 58px;
  margin-top: -16px;
}

.bias-number-large .bias-type {
  font-size: 13px;
  letter-spacing: .15px;
  line-height: normal;
  margin-top: -1px;
}

.bias-number-small {
  top: -1.75em;
  gap: 5px;
  padding: 10px;
}

.bias-number-small .bias-value {
  font-size: 35px;
}

.bias-number-small .bias-type {
  font-size: 12px;
}

.bias-icon {
  margin-right: 0.5em;
  width: 66px;
  height: 66px;
  display: inline-flex;
}

.bias-header-left {
  display: inline-flex;
  align-items: center;
  zoom: 75%;
}

.section hr {
  border-style: solid;
  border-width: 1px;
  border-top-color: #dddddd;
  border-right-color: #dddddd;
  border-bottom-color: #dddddd;
  border-left-color: #dddddd;
}

.coming-soon {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  margin-top: 6em;
  width: 100%;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.coming-soon-text {
  text-align: center;
  font-family: "Inter", Helvetica;
  font-weight: 600;
  color: #585757;
  font-size: 40px;
  letter-spacing: -1.5px;
  line-height: normal;
}

.small-bias {
  position: relative;
}

.small-bias .pure-row-centered hr {
  width:  50%;
}

.small-bias > div:nth-child(4) > p {
  display: none;
}

.small-bias > div:nth-child(5) > p {
  font-size: 12px;
  text-align: right;
}

.small-bias .bias-icon {
  width: 42px;
  height: 42px;
}

.small-bias .bias-platform {
  margin-left: 1px;
  font-size: 24px;
}

.time-range-selection {
  border: 1px solid;
  border-color: var(--light-theme-medium-grey);
  border-radius: 4.86px;
  gap: 7.29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
  width: 175px;
}

.time-range-option {
  align-items: center;
  color: #585757;
  font-family: "Inter-Medium",Helvetica;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  height: 1.5em;
  justify-content: center;
  margin-top: -1px;
  width: 30.29px;
  text-align: center;
  display: inline-flex;
  height: 2em;
  cursor: pointer;
}

/* both .time-range-option AND .disabled */
.time-range-option.disabled {
  color: lightgray;
  /* disabled clicking */
  pointer-events: none;
}

.time-range-option-active {
  background-color: #1c1c1c;
  border-radius: 4px;
  color: #fff;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.time-range-selection + p {
  margin-top: 0em;
  margin-bottom: 0em;
}

.pure-legend {
  justify-content: flex-start
}

.pure-legend-icon {
  border-radius: 9px;
  height: 18px;
  width: 18px;
  margin-right: 0.5em;
}

.pure-legend-label {
  margin-right: 2em;
}

.pure-legend-blue {
  background-color: #3230cc;
}

.pure-legend-red {
  background-color: #c1250f;
}

.pure-legend-purple {
  background-color: #7a36c3;
}

.pure-legend-yellow {
  background-color: #e4a710;
}

.icon-x {
  background-color: #000;
  border-radius: 21.01px;
  height: 42.01px;
  margin: -0.01px;
  position: relative;
  width: 42.01px;
}

.icon-x img {
  height: 26px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 26px;
}

.icon-reddit {
  border-radius: 21.01px;
  height: 42.01px;
  margin: -0.01px;
  position: relative;
  width: 42.01px;
}

.icon-reddit img {
  height: 42.01px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 42.01px;
}

.our-mission hr {
  width: 100%;
}

.bias-conservative-extreme {
  background-color: var(--republican-red-darkest);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-conservative-significant {
  background-color: var(--republican-red-darker);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-conservative-minimal {
  background-color: var(--republican-red-light);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-mixed {
  background-color: var(--purple);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-liberal-minimal {
  background-color: var(--democrat-blue-lightest);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-liberal-significant {
  background-color: var(--democrat-blue-lighter);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}

.bias-liberal-extreme {
  background-color: var(--democrta-blue-darkest);
  opacity: 1;
  box-shadow: 0px 14px 14px #080c2f1a;
}
