.swiper {
    width: 100% !important;
    height: 100% !important;
}

.swiper-slide {
    text-align: center !important;
    font-size: 18px !important;
    background: #fff;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.swiper-slide img {
    display: block !important;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.swiper-button-next, .swiper-button-prev {
    background-color: white; /* White background */
    opacity: 0.8;            /* Decrease opacity (transparency) */
    border-radius: 50%;       /* Round shape */
    width: 60px;             /* Width of the circle */
    height: 60px;            /* Height of the circle */
    display: flex;           /* To center the arrow inside the circle */
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
}

.swiper-pagination-bullet {
    /* larger size */
    width: 15px;
    height: 15px;
}

.eph-button {
  background: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size:larger;
}

.youtube-carousel {
  position: relative;
  height: 340px;
  margin: 0 -1.5em 0 -1.5em;
  padding: 0 1.5em 2em 1em;
  background-color: #fdfdfd;
  overflow: hidden;
}

/*lpk added to get header on real carousel*/
.youtube-carousel-container {
  position: relative;
  width: 1122px;
  height: 500px;
  background-color: #fdfdfd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--drop-shadow-box);
}

.youtube-carousel > #carousel {
overflow: visible;
}

.swiper-pagination {
bottom: -30px !important;
}

.swiper-button-next, .swiper-button-prev {
background: #fdfdfd;
opacity: 1;
box-shadow: 0 5px 10px rgba(0,0,0,0.4);
border: 1px solid rgba(0,0,0,0.1);
width: 2.5em;
height: 2.5em;
border-radius: 6px;
transition: all 0.2s ease-in-out;
}

.swiper-button-next:after, .swiper-button-prev:after {
font-size: 20px;
font-weight: 700;
transition: all 0.2s ease-in-out;
}

.swiper-button-prev:after {
transform: translateX(-2px);
}

.swiper-button-next:after {
transform: translateX(1px);
}

.swiper-button-next:hover, .swiper-button-prev:hover {
background: #007aff;
transform: translateY(-3px);
box-shadow: 0 7px 14px rgba(0,0,0,0.2);
}

.swiper-button-next:hover:after, .swiper-button-prev:hover:after {
color: #fff;
}

.swiper-slide {
border: 1px solid rgba(0,0,0,0.1);
border-radius: 4px;
overflow: hidden;
box-shadow: 0 5px 10px rgba(0,0,0,0.05);
}

.swiper-pagination-bullet {
background: rgba(0,0,0,0.15);
opacity: 1;
}

.swiper-pagination-bullet:hover, .swiper-pagination-bullet-active {
background: #007aff;
}