#countdown-minutes {
    width: 70px;  /* Adjust based on the maximum expected width of the minutes */
    margin-right: 3px;
    overflow: hidden;
    text-align: right;  /* Aligned to the right */
}

#countdown-seconds {
    width: 70px;  /* Adjust based on the maximum expected width of the seconds */
    margin-left: 3px;
    overflow: hidden;
    text-align: left;  /* Aligned to the left */
}
