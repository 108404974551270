#vote-nationwide-bias {
  /* margin-left: 0.5em;
  margin-right: 0.5em; */
}

#vote-pennsylvania-bias {
  margin-left: 0.5em;
}

#vote-nevada-bias {
  margin-right: 0.5em;
}

#vote-michigan-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#vote-northCarolina-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}



#vote-arizona-bias {
  margin-right: 0.5em;
}

#vote-georgia-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#vote-wisconsin-bias {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.bias-number {
  background-color: blue;
  top: -1.5em;
  left: 0.25em;
  right: 0;
  box-shadow: var(--shadow);
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  z-index: 1;
  zoom: 85%
}

.bias-number-large {
  padding: 20px 15px 16px;
}

.bias-header {
  align-items: center;
  display: inline-flex;
  height: 3em;
  margin-bottom: 2em;
}

.bias-header .section-large-header {
  font-size: 40px;
}

.bias-value {
  color: #fff;
  font-family: Inter,Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.bias-type {
  color: #fff;
  font-family: Inter,Helvetica;
  font-weight: 400;
  position: relative;
  width: fit-content;
  text-align: center;
}

.bias-number-large .bias-value {
  font-size: 58px;
  margin-top: -16px;
}

.bias-number-large .bias-type {
  font-size: 13px;
  letter-spacing: .15px;
  line-height: normal;
  margin-top: -1px;
}

.bias-number-small {
  top: -1.75em;
  gap: 5px;
  padding: 10px;
}

.bias-number-small .bias-value {
  font-size: 35px;
}

.bias-number-small .bias-type {
  font-size: 12px;
}

.bias-icon {
  margin-right: 0.5em;
  width: 66px;
  height: 66px;
  display: inline-flex;
}

.bias-header-left {
  display: inline-flex;
  align-items: center;
  zoom: 75%;
}

.section hr {
  border-style: solid;
  border-width: 1px;
  border-top-color: #dddddd;
  border-right-color: #dddddd;
  border-bottom-color: #dddddd;
  border-left-color: #dddddd;
}

.coming-soon {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  margin-top: 6em;
  width: 100%;
  height: 75%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.coming-soon-text {
  text-align: center;
  font-family: "Inter", Helvetica;
  font-weight: 600;
  color: #585757;
  font-size: 40px;
  letter-spacing: -1.5px;
  line-height: normal;
}

.small-bias {
  position: relative;
}

.small-bias .pure-row-centered hr {
  width:  50%;
}

.small-bias > div:nth-child(4) > p {
  display: none;
}

.small-bias > div:nth-child(5) > p {
  font-size: 12px;
  text-align: right;
}

.small-bias .bias-icon {
  width: 42px;
  height: 42px;
}

.small-bias .bias-platform {
  margin-left: 1px;
  font-size: 24px;
}

.time-range-selection {
  border: 1px solid;
  border-color: var(--light-theme-medium-grey);
  border-radius: 4.86px;
  gap: 7.29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
  width: 175px;
}

.time-range-option {
  align-items: center;
  color: #585757;
  font-family: "Inter-Medium",Helvetica;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  height: 1.5em;
  justify-content: center;
  margin-top: -1px;
  width: 30.29px;
  text-align: center;
  display: inline-flex;
  height: 2em;
  cursor: pointer;
}

/* both .time-range-option AND .disabled */
.time-range-option.disabled {
  color: lightgray;
  /* disabled clicking */
  pointer-events: none;
}

.time-range-option-active {
  background-color: #1c1c1c;
  border-radius: 4px;
  color: #fff;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.time-range-selection + p {
  margin-top: 0em;
  margin-bottom: 0em;
}

.pure-legend {
  justify-content: flex-start
}

.pure-legend-icon {
  border-radius: 9px;
  height: 18px;
  width: 18px;
  margin-right: 0.5em;
}

.pure-legend-label {
  margin-right: 2em;
}

.pure-legend-blue {
  background-color: #3230cc;
}

.pure-legend-red {
  background-color: #c1250f;
}

.pure-legend-purple {
  background-color: #7a36c3;
}

.pure-legend-yellow {
  background-color: #e4a710;
}

.hero-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
